<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
        <b-link
          class="alert-link"
          :to="{ name: 'auth-final-list'}"
        >
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
      </div>
    </b-alert>


    <template>
      <b-card class="bg-white">
        <div class="text-primary h2">
          <feather-icon
            icon="StarIcon"
            size="25"
            class="mr-0 mr-sm-50"
            variant="primary"
          />
            <span class="d-none d-sm-inline"> Paciente: {{ Name }}</span>
        </div>
      </b-card>
      <div v-for="element of elementData">
        <element-edit-tab
          :element-data="element"
          :clinical-laboratory-list="clinicalLaboratoryList"
          class="mt-2 pt-75"
        />
      </div>
    </template>
    
  </div>
</template>

<script>
import {
   BAlert, BLink, BCard
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementEditTab from './EditCard.vue'
import moment from 'moment'

export default {
  components: {
    BAlert,
    BLink,
    BCard,
    ElementEditTab: ElementEditTab,
  },
  data() {
    return {
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-vaccine-schema',
      RESOURCES_ELEMENT_NAME: 'vaccine',
      elementData: undefined,
      clinicalLaboratoryList: [],
      Name: ''
    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, elementStoreModule)
    this.elementData = await this.fetchElement()
    this.Name = this.elementData.length > 0 && this.elementData[0].medical_order ? 
                this.elementData[0].medical_order.patient_name || '' : '';
    this.clinicalLaboratoryList = (await this.fetch_companies()).filter(element => element.supplier_type_key === 'VACCINE') 
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_key_value(type, deep, parent) {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_key_value', { type: type, deep: deep, parent: parent }).then(response => {
        var list = response.data.items
        list.unshift({ id: -1, key: this.$t('list.defaultOption'), value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return []
        }
      })
    },
    async fetchElement() {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
        .then(response => { 
          return response.data 
        })
        .catch(error => {
          return undefined
        })
    },
    async fetch_companies() {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_companies', {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: '',
            companyType: 'SUPPLIER'
          }
        })
        .then(response => {
          return response.data.items
        })
        .catch(() => {
          return []
        })
    },
    async addFile(fileData, prst_uuid, camo_id) {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/addFile', { fileData: fileData, prst_uuid: prst_uuid, camo_id: camo_id })
      .then(response => {
        return response.data
      }).catch(error => {
        console.log(error)
        return undefined
      }) 
    },
    async save() {
      let method = this.ARL_CODES_APP_STORE_MODULE_NAME + '/save'
      var _self = this
      var error = false
      for (const element of this.elementData) {
        const fileFields = [
          { fileKey: 'first_carnet_file', idKey: 'first_carnet_id' , key : 'Primera dosis'},
          { fileKey: 'second_carnet_file', idKey: 'second_carnet_id', key: 'Segunda dosis'},
          { fileKey: 'third_carnet_file', idKey: 'third_carnet_id', key:'Tercera dosis'},
          { fileKey: 'fourth_carnet_file', idKey: 'fourth_carnet_id' , key :'Cuarta dosis'},
          { fileKey: 'antibody_carnet_file', idKey: 'antibody_carnet_id', key: 'Anticuerpo'}
        ];

        for (const { fileKey, idKey , key } of fileFields) {
          if (element[fileKey]) {
            let originalFile = element[fileKey]
            let originalFileName = originalFile.name
            let newName = originalFileName.replace(/(\.[\w\d_-]+)$/i, `-${key}$1`)
            let newFile = new File([originalFile], newName, { type: originalFile.type, lastModified: originalFile.lastModified })
            let InstFormData = new FormData()
            InstFormData.append('file', newFile)
            element[idKey] = await this.addFile(InstFormData, element.medical_order.step.uuid, element.medical_order.id)
            if (element[idKey]) {
              element[fileKey] = null
            } else {
              error = true
            }
          }
        }
      }

      if (error) {
        console.log(error)
        _self.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error registrando el adjunto',
            icon: 'ErrorIcon',
            variant: 'danger',
            text: `Error al guardar el esquema de vacunación`,
          },
        })
      } else {
        _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Adjunto anexado',
              icon: 'SaveIcon',
              variant: 'success',
              text: `El esquema de vacunación se guardó correctamente`,
            },
          })
        for (const element of this.elementData) {
          error = error || ! await store.dispatch(method, {elementData: element})
          .then(response => {
            return true
          })
          .catch((error) => {
            console.log(error)
            return false
          })
        }
        if (error) {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.title'),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.failure'),
            },
          })
        } else {
          _self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.title'),
                icon: 'SaveIcon',
                variant: 'success',
                text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.success'),
              },
            })
            // _self.$router.replace({ name: 'auth-final-list' })
        }
      }
    }
  }
}
</script>

<style>

</style>
